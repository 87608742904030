import React, {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import {makeStyles, useTheme} from "@mui/styles";
import {useParams} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Ajax} from "../../../helpers";
import Loader from "../../Partials/Loader";
import Container from "@mui/material/Container";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 70,
  },
  tabs: {
    width: "100%",
    borderLeft: "12px solid #bbb9bd",
    borderBottom: "2px dotted #BBB8BC",
    paddingLeft: 15,
    color: "#bbb9bd",
    "& button": {
      borderRight: "2px solid #ccc",
    },
  },
  content: {
    paddingLeft: 40,
    paddingRight: 40,
  }
}));

export default function AboutUs() {
  let { lang } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [pages, setPages] = useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/page/about-us", lang)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [lang])

  if (!isLoaded || error) {
    return (<Loader/>);
  }

  return (
    <Container className={classes.root}>
      <Grid item xs={12}>
        <Tabs
          className={classes.tabs}
          TabIndicatorProps={{ children: <span /> }}
          orientation={isMobile ? "vertical" : "horizontal"}
          variant="fullWidth"
          onChange={(e, newIndex) => setTabIndex(newIndex)}
          value={tabIndex}
        >
          <Tab value={0} disableRipple label={pages['about-us'].title}/>
          <Tab value={1} disableRipple label={pages['purpose-and-activity'].title}/>
          <Tab value={2} disableRipple label={pages['leading-staff'].title}/>
          <Tab value={3} disableRipple label={pages['history'].title}/>
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.content} hidden={tabIndex !== 0} dangerouslySetInnerHTML={{__html: pages['about-us'].body}}/>
        <div className={classes.content} hidden={tabIndex !== 1} dangerouslySetInnerHTML={{__html: pages['purpose-and-activity'].body}}/>
        <div className={classes.content} hidden={tabIndex !== 2} dangerouslySetInnerHTML={{__html: pages['leading-staff'].body}}/>
        <div className={classes.content} hidden={tabIndex !== 3} dangerouslySetInnerHTML={{__html: pages['history'].body}}/>
      </Grid>
    </Container>
  )
}
