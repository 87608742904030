import React, { useEffect, useState } from "react"
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import { Ajax } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {},
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    textTransform: "uppercase",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  item:{
    padding: "0 23px 0 23px",
    borderBottom: "2px dotted #ccc",
    marginBottom: 15,
    paddingBottom: 15,
  },
  title: {
    fontFamily: "Arian-AMU",
    textTransform: "uppercase",
    height: 40,
    paddingTop: 11,
    display: "inline-block",
    width: "100%",
    color: "#000",
    margin: 0,
    fontSize: 17,
    fontWeight: 700,
  },
  description: {
    display: "block",
    width: "100%",
    margin: 0,
    textAlign: "left",
    boxSizing: "border-box",
    "& > p": {
      lineHeight: "23px",
      fontSize: 17,
      color: "#A7A9AC",
      margin: "5px 0",
    },
  },
  type: {
    width: "100%",
    fontSize: 12,
    color: "#000",
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SearchPage() {
  const classes = useStyles();
  const q = useQuery();
  let {lang} = useParams();
  const {t} = useTranslation('common');
  const qe = q.get('q');
  const [query, setQuery] = useState(qe);
  const [shouldLoadData,setShouldLoadData] = useState(true);
  const [books, setBooks] = useState([]);
  const [articles, setArticles] = useState([]);
  let history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    setShouldLoadData(true);
    const url = `${history.location.pathname}?q=${query}`;
    history.push(url);
  }

  function handleChange(e) {
    setQuery(e.target.value);
  }

  useEffect(() => {
    setQuery(qe);
    setShouldLoadData(true);
  }, [lang, qe])

  useEffect(() => {
    if (!shouldLoadData) {
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}/search?q=${encodeURI(query)}`;

    Ajax(url, lang)
      .then(res => res.json())
      .then((res) => {
        setBooks(res.books);
        setArticles(res.articles);
    });

    setShouldLoadData(false);
  }, [shouldLoadData, query, lang])

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Paper component="form" className={classes.searchRoot} onSubmit={handleSubmit}>
            <InputBase
              onChange={handleChange}
              value={query}
              className={classes.input}
              placeholder={t("search")}
              inputProps={{'aria-label': 'search google maps'}}
            />
            { query && query.length > 0 &&
              <IconButton className={classes.iconButton} aria-label="clear" onClick={() => (setQuery(''))}>
                <CloseIcon/>
              </IconButton>
            }
            <Divider className={classes.divider} orientation="vertical"/>
            <IconButton className={classes.iconButton} aria-label="search" type="submit">
              <SearchIcon/>
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      {
        books.map((book) => (
          <Grid item xs={12} key={book.id} className={classes.item}>
            <div>
              <Link className={classes.title} to={`/${lang}/category/books/${book.slug}`}>{book.title}</Link>
            </div>
            <div className={classes.description}>
              <p>{book.short_description}</p>
            </div>
            <div className={classes.type}>
              {t('books')}
            </div>
          </Grid>
        ))
      }
      {
        articles.map((article) => (
          <Grid item xs={12} key={article.id} className={classes.item}>
            <div>
              <Link className={classes.title} to={`/${lang}/category/articles/${article.slug}`}>
                {article.title}
              </Link>
            </div>
            <div className={classes.description}>
              <p>{article.short_description}</p>
            </div>
            <div className={classes.type}>
              {t('articles')}
            </div>
          </Grid>
        ))
      }
    </Container>
  );
}
