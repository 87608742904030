import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {Link, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Ajax, getFormatImage} from "helpers";
import Loader from "../Partials/Loader";

const useStyles = makeStyles(() => ({
  pdf: {
    boxShadow: "0 0 7px 0px #0000008c",
  },
  title: {
    display: "inline-block",
    width: "100%",
    color: "#A7A9AC",
    fontWeight: 700,
    fontSize: 15,
    paddingLeft: 15,
    textTransform: "uppercase",
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  },
  description: {
    marginBottom: 0,
    fontSize: 14,
    paddingLeft: 15,
  },
}));

export default function ShowTimelineDocuments() {
  let { slug, lang } = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [title, setTitle] = useState();
  const [documents, setDocuments] = useState([]);

  function Document ({document}) {
    const bgImage = getFormatImage(document.format);
    return (
        <Grid container direction="row" justifyContent="space-between" className={classes.root}>
          <Grid item xs={2}>
            <img alt="" className={classes.pdf} src={bgImage} />
          </Grid>
          <Grid item xs={10}>
            <div>
              <div>
                <span className={classes.title}>{document.original_name}</span>
              </div>
              <div>
                <a className={classes.description} rel="noreferrer" target="_blank" href={document.download_link}>Download</a>
              </div>
            </div>
          </Grid>
        </Grid>
    );
  }

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/timeline/" + slug + "/documents", lang)
      .then(res => res.json())
      .then(
        (result) => {
          setDocuments(result.documents);
          setTitle(result.title);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [slug, lang])

  if (!isLoaded) {
    return (<Loader/>);
  }

  if (error) {
    console.error(error);
  }

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item xs={12}>
        <Grid className={classes.readMore} container justifyContent="space-between">
          <Grid item>
            <Link to={`/${lang}/archive`}>
              <h2 className={classes.subheader}>{title}</h2>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {
        documents.map(document => (
          <Grid key={document.download_link} item xs={4}>
            <Document document={document}/>
          </Grid>
        ))
      }
    </Grid>
  );
}
