import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import newTheme from "../newTheme";
import Search from "../Inputs/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {isValidUrl} from "../../helpers";
import Hidden from "@mui/material/Hidden";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    cursor: "pointer",
    verticalAlign: "text-bottom",
    height: 23,
    fontSize: 15,
    fontFamily: "Arian-AMU",
    textDecoration: "none",
    color: "#fff",
    textTransform: "uppercase",
    "&:hover": {
      fontWeight: "bold",
    }
  },
  toolbar: {
    minHeight: 45,
    textTransform: "uppercase",
  },
  submenu: {
    transition: "all 0.3s ease",
    position: "absolute",
    backgroundColor: "#405662",
    listStyle: "none",
    marginTop: 10,
    padding: 0,
    "& > li": {
      display: "block",
      transition: "all 0.3s ease",
      height: "auto",
      padding: "10px 25px",
    }
  },
}));

export default function NavMenu({menu}) {
  const classes = useStyles();
  const {pathname} = useLocation();
  const lang = pathname.substring(1, 3);
  const [resourceOpen, setResourceOpen] = useState(false);

  function getUrl(string) {
    if (isValidUrl(string)) {
      return string;
    }

    return `/${lang}/${string}`;
  }

  function menuItem(item) {
    if (item.children.length === 0) {
      return <Link key={item.id} className={classes.menuItem} target={item.target} to={getUrl(item.url)}>{item.title}</Link>;
    }

    return (
      <div key={item.id} onMouseEnter={() => setResourceOpen(item.id)} onMouseLeave={() => setResourceOpen(null)}>
        <span className={classes.menuItem}>{item.title}</span>
        <ArrowDropDownIcon className={classes.menuItem} style={{fontSize: 30}}/>
        <ul className={classes.submenu}
            style={{
              visibility: resourceOpen === item.id ? 'visible' : "hidden",
              opacity: resourceOpen === item.id ? 1 : 0,
            }}
        >
          {
            item.children.map(child => {
              return (
                <li key={child.id}>
                  <Link key={child.id} target={child.target} className={classes.menuItem} to={getUrl(child.url)}>{child.title}</Link>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  if (menu.length === 0) {
    return null;
  }

  return (
    <ThemeProvider theme={newTheme}>
      <Hidden only={['xs', 'sm']}>
        <AppBar position="static">
          <Container>
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                {
                  menu.map(item => {
                    return menuItem(item);
                  })
                }
                <Search/>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Hidden>
    </ThemeProvider>
  );
}
