import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Ajax } from "helpers";
import Loader from "../Partials/Loader";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  contentBody: {
    textAlign: "justify",
  },
  mainContent: {
    padding: "0 15px 0 15px",
    "& > p": {
      display: "inline-block",
      color: "#565a5c",
      width: "100%",
      fontSize: 18,
      textTransform: "uppercase",
      margin: "0 0 10px 0",
    },
    "& > div": {
      display: "inline-block",
      width: "100%",
      marginBottom: 35,
      boxSizing: "border-box",
    }
  },
  description: {
    display: "inline-block",
    width: "100%",
    fontSize: 15,
    fontFamily: "'MoshVerdana' !important",
    color: "#000",
  },
}));

export default function ShowTimelineAbout() {
  let { slug, lang } = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState([]);

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/timeline/" + slug + "/about", lang)
      .then(res => res.json())
      .then(
        (result) => {
          setPage(result);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [slug, lang])

  if (!isLoaded) {
    return (<Loader/>);
  }

  if (error) {
    console.error(error);
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.contentBody} container justifyContent="center" alignItems="flex-start">
            <Grid item xs={12} className={classes.mainContent}>
              <p>{page.title}</p>
              <div className={classes.description} dangerouslySetInnerHTML={{__html: page.body}}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
