import React, { useEffect, useState } from "react";
import {Link, Route, Switch, useParams} from "react-router-dom";
import ArticlesGroup from "../Components/Resources/Articles/ArticlesGroup";
import BooksGroup from "../Components/Resources/Books/BooksGroup";
import VideosGroup from "../Components/Resources/Videos/VideosGroup";
import Grid from "@mui/material/Grid";
import Filters from "../Components/Inputs/Filters";
import { Ajax, setPageTitle, useQuery } from "helpers";
import Pagination from '@mui/material/Pagination';
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PaginationItem from "@mui/material/PaginationItem";
import Loader from "../Components/Partials/Loader";

const useStyles = makeStyles(() => ({
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  }
}));

export default function WithFilters() {
  const classes = useStyles();
  let {slug, lang} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [authorFilter, setAuthorFilter] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [lastPage, setLastPages] = useState(null);
  const {t} = useTranslation('common');
  let query = useQuery();
  let currentPage = parseInt(query.get('page'));
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  let limit = slug === 'book' ? 8 : 9;

  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/${slug}?limit=${limit}&page=${currentPage}`;
    setPageTitle()
    setIsLoaded(false)

    if (authorFilter != null) {
      url = url + '&author=' + authorFilter;
    }

    if (yearFilter != null) {
      url = `${url}&year=${yearFilter}`;
    }

    if (categoryFilter != null) {
      url = `${url}&category=${categoryFilter}`;
    }

    Ajax(url, lang)
      .then(res => res.json())
      .then(
        (result) => {
          setLastPages(result.meta.last_page);
          setItems(result[slug]);
          Ajax(`${process.env.REACT_APP_BASE_URL}/authors/?type=${slug}`, lang)
            .then(res => res.json())
            .then(
              (result) => {
                setAuthors(result.authors);
                Ajax(`${process.env.REACT_APP_BASE_URL}/categories`, lang)
                  .then(res => res.json())
                  .then((result) => {
                    setCategories(result);
                    setIsLoaded(true);
                  })
              }
            )
        }
      )

    return () => {
    }
  }, [yearFilter, authorFilter, categoryFilter, lang, currentPage, limit, slug])

  if (!isLoaded) {
      return <Loader/>;
  }

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item xs={12}>
        <Grid className={classes.readMore} container justifyContent="space-between">
          <Grid item>
            <h2 className={classes.subheader}>{t(slug)}</h2>
          </Grid>
        </Grid>
      </Grid>

      <Filters onCategoryChange={setCategoryFilter} categories={categories} onAuthorChange={setAuthorFilter} onYearChange={setYearFilter} authors={authors}/>
      <Switch>
        <Route path="/:lang/category/articles" exact={true}>
          <ArticlesGroup isLoaded={isLoaded} articles={items}/>
        </Route>
        <Route path="/:lang/category/books" exact={true}>
          <BooksGroup isLoaded={isLoaded} books={items}/>
        </Route>
        <Route path="/:lang/category/video" exact={true}>
          <VideosGroup isLoaded={isLoaded} videos={items}/>
        </Route>
      </Switch>
      <Grid item xs={12}>
        <Pagination count={lastPage}
                    color="primary"
                    page={currentPage}
                    renderItem={(item) => (
                        <PaginationItem
                            component={Link}
                            to={`/${lang}/category/${slug}?page=${item.page}`}
                            {...item}
                        />
                    )}
        />
      </Grid>
    </Grid>
  );
}
