import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {Ajax, AjaxPost} from "../../helpers";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import Loader from "../Partials/Loader";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from "images/Folder.jpg";
import PdfIcon from "images/pdf.svg";
import PdfIcon2 from "images/pdf2.svg";
import LinkIcon from "images/link.svg";
import JpgIcon from "images/jpg.svg";
import MovIcon from "images/mov.svg";
import NoneIcon from "images/none.svg";
import PptIcon from "images/ppt.svg";
import TxtIcon from "images/txt.svg";
import WordIcon from "images/word.svg";
import XlsxIcon from "images/xlsx.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import {Button} from "@mui/material";
import {TypeSpecimen} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';

const ICON_MAPPING = {
  pdf: PdfIcon2,
  png: JpgIcon,
  jpg: JpgIcon,
  jpeg: JpgIcon,
  doc: WordIcon,
  docx: WordIcon,
  mov: MovIcon,
  xlsx: XlsxIcon,
  pptx: PptIcon,
  none: NoneIcon
}


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "& .MuiButton-contained": {
      backgroundColor: '#FAAE4C',
      color: 'white'
    }
  },
  container: {
    "width":"1080px",
    "height":"100vh",
    "display":"flex",
    "flexDirection":"column",
    "alignItems":"center",
  },
  contentBox: {"display":"flex","alignItems":"center","gap":"20px"},
  card: {"width":"200px","height":"200px","display":"flex","alignItems":"center","justifyContent":"center"},
  actionBtns: {"display":"flex","alignItems":"center","gap":"60px","marginTop":"50px"},
  btn: {"fontSize":"15px","fontWeight":"500","color":"#002d36","backgroundColor":"#99c6cf","borderRadius":"6px","padding":"10px 25px","border":"none","cursor":"pointer","transition":"all 0.5s ease"},

  item: {
    marginBottom: 10,
    display: "flex",
    justifyContent: 'center',
    position: 'relative',
    width: 'max-content'
  },
  line: {
    content: "''",
    position: "absolute",
    width: 6,
    backgroundColor: "#D5D5D5",
    top: 0,
    bottom: 0,
    left: "41.6%",
    marginLeft: -3,
  },
  itemLeft: {
    paddingLeft: 20,
  },
  itemRight: {
    paddingLeft: 70,
    [theme.breakpoints.up('md')]: {
      "&::before": {
        content: "''",
        width: 120,
        height: 4,
        backgroundColor: "#D5D5D5",
        position: "absolute",
        left: "41.6%",
        marginLeft: -60,
        marginTop: 43,
        zIndex: 2,
      },
      "&::after": {
        content: "''",
        width: 24,
        height: 24,
        backgroundColor: "#fff",
        border: "2px solid #D5D5D5",
        position: "absolute",
        left: "41.6%",
        marginLeft: -12,
        marginTop: -167,
        zIndex: 3,
        borderRadius: 30,
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
    },
  },
  year: {
    color: "#FAAE4C",
    textAlign: "center",
    fontSize: 72,
    fontWeight: 900,
  },
  arrowUpIcon: {
    marginTop: -16,
    "& > svg": {
      fontSize: 82,
      color: "#D5D5D5",
      left: "41.6%",
      marginLeft: -41,
      position: "relative",
    }
  },
  circleIcon: {
    width: "100%",
    marginTop: 20,
    marginBottom: -10,
    "& > svg": {
      fontSize: 24,
      left: "41.6%",
      marginLeft: -12,
      color: "#B0B0B0",
      bottom: 0,
      zIndex: 2,
      position: "relative",
    }
  },
  paper: {
    borderRadius: "none",
    border: "none",
    padding: '6px 16px',
    minHeight: 200,
    boxShadow: "none",
  },
  itemLink: {
    color: "#FAAE4C",
    textTransform: "capitalize",
    padding: 10,
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    "& > span": {
      padding: 5,
    }
  },
  button: {
    borderColor: "#FAAE4C",
    color: "#FAAE4C",
    width: '134px',
    height: '60px',
    borderWidth: '4px',
    fontSize: '32px',
    fontWeight: 600,

    "&:hover": {
      borderColor: "#FAAE4C",
      color: "white",
      borderWidth: '4px',
      backgroundColor: '#FAAE4C'
    },

    "&::after, &::before": {
      content: '""',
      width: '90px',
      height: '6px',
      verticalAlign: 'super',
      backgroundColor: '#9C9CA4E8',
      display: "block",
      minWidth: '130px',
      margin: '28px',
    },
  },
  relativeBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '600px',
    minWidth: '275px',

    "& + &": {
      marginLeft: '110px',
    }
  },
  absoluteBox: {
    width: '275px',
    maxHeight: '480px',
    bottom: '40px',
    marginTop: '32px',
    overflowY: 'scroll',
    border: '1px solid #D9D9D9',
    backgroundColor: '#fafafa',

    "&::before": {
      content: '""',
      width: '0',
      height: '40px',
      position: 'absolute',
      border: '2px solid #455661',
      top: '60px',
      left: '50%',
    }
  },
  otherLinks: {
    paddingTop: 20,
    width: 430,
  },
  title: {
    color: "#000",
    paddingBottom: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 30,
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0
  },
  listItem: {
    backgroundColor: '#fef9f2',
    color: '#F0B15F',
    textDecoration: 'underline',
    marginBottom: '16px',
    cursor: 'pointer'
  },
  listItemGeneral: {
    backgroundColor: '#f7f8ff',
    color: '#003C5C',
    textDecoration: 'underline',
    marginBottom: '16px'
  },
  listItemIcon: {
    marginRight: '-10px'
  },
  summary: {
    backgroundColor: '#f4f4f4'
  },
  leftArrow: {
    position: "absolute",
    left: '-55px',
  },
  rightArrow: {
    position: "absolute",
    right: '-55px',
  },
  goBackFolder: {
    display: 'flex',
    marginTop: '16px',
    marginBottom: '32px',
    maxWidth: '100%',

    "& img": {
      margin: '10px'
    },
    "& h4": {
      color: 'black',
      fontWeight: 600
    }
  },
  goBackBtn: {
    maxWidth: '80px',
    margin: '10px',
  },
  goBackFolderIcons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '10px',
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  },
  fileIcons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
  fileName: {
    maxWidth: '180px',
    wordWrap: 'break-word',
    textAlign: 'center'
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  }
}));

export default function NewArchive() {
  const classes = useStyles();
  let {lang} = useParams();
  const [items, setItems] = useState([]);
  const [singleYearData, setSingleYearData] = useState({});
  const {t} = useTranslation('common');
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftIcon, setShowLeftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(false);
  const [activeYears, setActiveYears] = useState([]);
  const [folderContent, setSingleFolderContent] = useState([]);
  const [folderContentTitle, setFolderContentTitle] = useState('');

  console.log(items);

  const containerRef = useRef();
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;

    setScrollPosition(newScrollPosition);

    containerRef.current.scrollLeft = newScrollPosition;
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_URL}/timeline`;
    Ajax(url, lang)
      .then(res => res.json())
      .then((res) => {
        setItems(res.timeline);
        setIsLoaded(true);
      });
  }, [lang]);

  const getSingleFile = (year) => {
    if (!singleYearData[year]) {
      const url = `${process.env.REACT_APP_BASE_URL}/timeline/${year}/file`;
      Ajax(url, lang)
        .then(res => res.json())
        .then((res) => {
          setSingleYearData({...singleYearData, [year]: res})
        });
    }
  };

  const geSingleFolder = (year, movement, file) => {
    // if (!singleYearData[year]) {
      setIsLoaded(false);

      const url = `${process.env.REACT_APP_BASE_URL}/timeline/folder`;
      AjaxPost(url, lang, {year, movement, file}, 'POST')
        .then(res => res.json())
        .then((res) => {
          setIsLoaded(true);
          setSingleFolderContent(res);
        });
    // }
  };

  const handleScrolling = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
      if (scrollLeft > 50) {
        setShowLeftIcon(true);
      } else {
        setShowLeftIcon(false);
      }

      if (scrollWidth > 1150 && (scrollLeft + offsetWidth !== scrollWidth)) {
        setShowRightIcon(true);
      } else {
        setShowRightIcon(false);
      }
    }
  };

  const handleYearClick = (year) => {
    getSingleFile(year);
    if (activeYears.includes(year)) {
      setActiveYears(activeYears.filter(item => item !== year));
    } else {
      setActiveYears([...activeYears, year])
    }
  }

  if (!isLoaded) {
    return (<Loader/>);
  }

  function ArchiveItem() {
    return (
      <Grid lg={12} sm={12}>
        {folderContent.length ? FileView() : TimelineView()}
      </Grid>
    );
  }

  function FileView() {
    return (
      <Grid container direction="column" justifyContent="flex-start" className={classes.item}>
        <Button
          startIcon={<ArrowBackIosIcon />}
          className={classes.goBackBtn}
          onClick={() => {
            setSingleFolderContent([])
          }}
        >
          Back
        </Button>
        <div className={classes.goBackFolder}>
          <img className={classes.logoImage} alt="" src={FolderIcon} style={{ width: '30px', height: '26px' }}/>
          <Typography variant="h4" component="h4" style={{ width: '1000px', wordWrap: 'break-word' }}>
            {folderContentTitle}
          </Typography>
        </div>
        <div className={classes.goBackFolderIcons}>
          {folderContent.length && folderContent.map(folderFile => (
            <div key={folderFile.path} className={classes.fileIcons}>
              <IconButton
                href={folderFile.full_path}
                target="_blank"
                size="large"
              >
                <img className={classes.imageIcon} alt="" src={ICON_MAPPING[folderFile.extension.toLowerCase()] ?? ICON_MAPPING['none'] }/>
              </IconButton>
              <span className={classes.fileName}>{`${folderFile.filename}.${folderFile.extension}`}</span>
            </div>
          ))}
        </div>
      </Grid>
    )
  }

  function TimelineView() {
    return (<Grid container direction="row" justifyContent="flex-start" className={classes.item}>
      <Grid item xs={12}>
        <Grid className={classes.readMore} container justifyContent="space-between">
          <Grid item>
            <h2 className={classes.subheader}>{t('archive')}</h2>
          </Grid>
        </Grid>
      </Grid>
      {showLeftIcon && (
        <div className={classes.leftArrow}>
          <IconButton size="large" onClick={() => handleScroll(-300)}>
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      {showRightIcon && (
        <div className={classes.rightArrow}>
          <IconButton size="large" onClick={() => handleScroll(300)}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      <div className={classes.container}>
        <div
          ref={containerRef}
          style={{
            width: "1080px",
            overflowX: "scroll",
            scrollBehavior: "smooth",
          }}
          onScroll={handleScrolling}
        >
          <div className={classes.contentBox}>
            {items.length && items.map((item) => (
              <div className={classes.relativeBox} key={item.id}>
                <LoadingButton
                  variant={activeYears.includes(item.year) ? 'contained' : 'outlined'}
                  size="large"
                  className={classes.button}
                  onClick={() => handleYearClick(item.year)}
                >
                  {item.year}
                </LoadingButton>
                {(activeYears.includes(item.year) && singleYearData[item.year]) ? ArchiveAccordion(singleYearData[item.year]) : (<></>)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Grid>)
  }

  function ArchiveAccordion(accordionData) {
    function compare(a, b) {
      if (a.type > b.type)
        return -1;
      if (a.type < b.type)
        return 1;
      return 0;
    }

    return (
      <div className={classes.absoluteBox}>
        {accordionData && accordionData.map(yearArch => (
        <Accordion key={yearArch.timestamp}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1-content"
            id="panel1-header"
            className={classes.summary}
          >
            {yearArch.basename ?? yearArch.filename}
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {yearArch.folders.sort(compare).map((folderFiles) => (
                folderFiles['type'] === 'dir' ? (
                    <ListItem className={classes.listItem} onClick={() => {
                      setFolderContentTitle(`${yearArch.basename}_${folderFiles.basename}`)
                      setSingleFolderContent(folderFiles.folderFiles)
                    }}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <img className={classes.logoImage} alt="" src={FolderIcon}/>
                      </ListItemIcon>
                      <ListItemText
                        primary={folderFiles.basename}
                      />
                    </ListItem>
                  ) : (
                  // <IconButton
                  //   href={folderFile.full_path}
                  //   target="_blank"
                  //   size="large"
                  // >
                  //   <img className={classes.imageIcon} alt="" src={ICON_MAPPING[folderFile.extension.toLowerCase()] ?? ICON_MAPPING['none'] }/>
                  // </IconButton>

                    <ListItem className={classes.listItemGeneral} component="a" target="_blank" href={folderFiles.full_path}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <img className={classes.logoImage} alt="" src={PdfIcon}/>
                      </ListItemIcon>
                      <ListItemText
                        primary={folderFiles.basename}
                      />
                    </ListItem>
                  )
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        ))}
      </div>
    )
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
      {ArchiveItem()}
    </Grid>
  );
}
