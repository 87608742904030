import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import ServicesGroup from "./ServicesGroup";
import {Ajax, useQuery} from "../../../helpers";
import {Link, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

const useStyles = makeStyles(() => ({
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  }
}));

export default function ServicesPage() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [services, setServices] = useState([]);
  const [lastPage, setLastPages] = useState(null);
  let query = useQuery();
  let currentPage = parseInt(query.get('page'));
  if (isNaN(currentPage)) {
    currentPage = 1;
  }
  const {lang} = useParams();
  const {t} = useTranslation('common');

  useEffect(() => {
    Ajax(`${process.env.REACT_APP_BASE_URL}/projects?page=${currentPage}`, lang)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setLastPages(result.meta.last_page);
          setServices(result.projects);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [lang, currentPage])

  console.log(lastPage);

  if (!isLoaded || !lastPage) {
    return null;
  }

  return (
    <Container>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.readMore} container justifyContent="space-between">
            <Grid item>
              <h2 className={classes.subheader}>{t("projects")}</h2>
            </Grid>
          </Grid>
        </Grid>

        <ServicesGroup error={error} isLoaded={isLoaded} services={services}/>
      </Grid>
      <Grid item xs={12}>
        <Pagination count={lastPage}
                    color="primary"
                    page={currentPage}
                    renderItem={(item) => (
                        <PaginationItem
                            component={Link}
                            to={`/${lang}/category/projects?page=${item.page}`}
                            {...item}
                        />
                    )}
        />
      </Grid>
    </Container>
  );
}
