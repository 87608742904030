import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Ajax } from "../../helpers";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircleIcon from '@mui/icons-material/Circle';
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { mdiBullhornOutline } from '@mdi/js';
import {useTranslation} from "react-i18next";
import Icon from '@mdi/react';
import Hidden from "@mui/material/Hidden";
import Loader from "../Partials/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    position: "relative"
  },
  item: {
    marginBottom: 10,
  },
  line: {
    content: "''",
    position: "absolute",
    width: 6,
    backgroundColor: "#D5D5D5",
    top: 0,
    bottom: 0,
    left: "41.6%",
    marginLeft: -3,
  },
  itemLeft: {
    paddingLeft: 20,
  },
  itemRight: {
    paddingLeft: 70,
    [theme.breakpoints.up('md')]: {
      "&::before": {
        content: "''",
        width: 120,
        height: 4,
        backgroundColor: "#D5D5D5",
        position: "absolute",
        left: "41.6%",
        marginLeft: -60,
        marginTop: 43,
        zIndex: 2,
      },
      "&::after": {
        content: "''",
        width: 24,
        height: 24,
        backgroundColor: "#fff",
        border: "2px solid #D5D5D5",
        position: "absolute",
        left: "41.6%",
        marginLeft: -12,
        marginTop: -167,
        zIndex: 3,
        borderRadius: 30,
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
    },
  },
  year: {
    color: "#FAAE4C",
    textAlign: "center",
    fontSize: 72,
    fontWeight: 900,
  },
  arrowUpIcon: {
    marginTop: -16,
    "& > svg": {
      fontSize: 82,
      color: "#D5D5D5",
      left: "41.6%",
      marginLeft: -41,
      position: "relative",
    }
  },
  circleIcon: {
    width: "100%",
    marginTop: 20,
    marginBottom: -10,
    "& > svg": {
      fontSize: 24,
      left: "41.6%",
      marginLeft: -12,
      color: "#B0B0B0",
      bottom: 0,
      zIndex: 2,
      position: "relative",
    }
  },
  paper: {
    borderRadius: "none",
    border: "none",
    padding: '6px 16px',
    minHeight: 200,
    boxShadow: "none",
  },
  itemLink: {
    color: "#FAAE4C",
    textTransform: "capitalize",
    padding: 10,
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    "& > span": {
      padding: 5,
    }
  },
  otherLinks: {
    paddingTop: 20,
    width: 430,
  },
  title: {
    color: "#000",
    paddingBottom: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 30,
  },
}));

export default function Archive() {
  const classes = useStyles();
  let {lang} = useParams();
  const [items, setItems] = useState([]);
  const {t} = useTranslation('common');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {

    const url = `${process.env.REACT_APP_BASE_URL}/timeline`;
    Ajax(url, lang)
      .then(res => res.json())
      .then((res) => {
        setItems(res.timeline);
        setIsLoaded(true);
      });
  }, [lang]);

  if (!isLoaded) {
    return (<Loader/>);
  }

  function ArchiveItem(item) {
    return (
      <Grid key={item.id} item lg={12} sm={12}>
        <Grid container direction="row" justifyContent="flex-start" className={classes.item}>
          <Hidden only={['sm', 'xs']}>
            <Grid item lg={5} sm={5} className={classes.itemLeft}>
            <Typography variant="body2" color="secondary" className={classes.year} >
              {item.year}
            </Typography>
          </Grid>
          </Hidden>
          <Grid item lg={7} sm={7} className={classes.itemRight} xs={{ display: "none" }}>
            <div className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title}>
                <span>{item.title}</span>
                <Divider/>
                <Grid container className={classes.otherLinks}>
                  {item.has_about &&
                    <Grid item sm={6}>
                      <Link to={`/${lang}/archive/` + item.slug + '/about'}  className={classes.itemLink}>
                        <InfoOutlinedIcon/>
                        <span>{t('about')}</span>
                      </Link>
                    </Grid>
                  }
                  {item.documents &&
                    <Grid item sm={6}>
                      <Link to={`/${lang}/archive/` + item.slug + '/documents'} className={classes.itemLink}>
                        <DescriptionOutlinedIcon/>
                        <span>{t('documents')}</span>
                      </Link>
                    </Grid>
                  }
                  {item.media &&
                    <Grid item sm={6}>
                      <Link to={`/${lang}/archive/` + item.slug + '/media'} className={classes.itemLink}>
                        <PermMediaOutlinedIcon/>
                        <span>{t('media')}</span>
                      </Link>
                    </Grid>
                  }
                  {item.has_publicity &&
                    <Grid item sm={6}>
                      <Link to={`/${lang}/archive/` + item.slug + '/publicity'}  className={classes.itemLink}>
                        <Icon path={mdiBullhornOutline} size={1}/>
                        <span>{t('publicity')}</span>
                      </Link>
                    </Grid>
                  }
                </Grid>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
      <Hidden only={['sm', 'xs']}>
        <Grid item lg={12} sm={12} className={classes.arrowUpIcon}>
          <ArrowUpwardIcon/>
        </Grid>
      </Hidden>
      {
        items.map((item) => (
          ArchiveItem(item)
        ))
      }
      <Hidden only={['sm', 'xs']}>
        <Grid item lg={12} sm={12} style={{ overflow: "hidden" }}>
          <div className={classes.line}/>
        </Grid>
      </Hidden>
      <Hidden only={['sm', 'xs']}>
        <Grid item lg={12} sm={12} className={classes.circleIcon}>
          <CircleIcon/>
        </Grid>
      </Hidden>
    </Grid>
  );
}
