import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Ajax } from "helpers";
import Loader from "../Partials/Loader";
import NotFound from "../Errors/NotFound";
import Error from "../Errors/Error";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  contentBody: {
    textAlign: "justify",
  },
  image: {
    width: "100%",
  },
  mainContent: {
    padding: "0 15px 0 15px",
    "& > p": {
      display: "inline-block",
      color: "#565a5c",
      width: "100%",
      fontSize: 18,
      textTransform: "uppercase",
      margin: "0 0 10px 0",
    },
    "& > div": {
      display: "inline-block",
      width: "100%",
      marginBottom: 35,
      boxSizing: "border-box",
    }
  },
  description: {
    display: "inline-block",
    width: "100%",
    fontSize: 15,
    fontFamily: "'MoshVerdana' !important",
    color: "#000",
  },
}));

export default function AuthorsPage() {
  let { slug, lang } = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [author, setAuthor] = useState([]);

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/authors/" + slug, lang)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setError(<NotFound/>);
          setIsLoaded(true);
        } else {
          setError(<Error/>);
          setIsLoaded(true);
        }
      })
      .then((result) => {
        if (result === undefined) {
          return;
        }
        setAuthor(result.data);
        setIsLoaded(true);
      })
  }, [slug, lang])

  if (!isLoaded) {
    return <Loader/>;
  }

  if (error) {
    return error;
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.contentBody} container justifyContent="center" alignItems="flex-start">
            <Grid item xs={1}>
              <img alt="" src={author.image} className={classes.image}/>
            </Grid>
            <Grid item xs={11} className={classes.mainContent}>
              <p>{author.name}</p>
              <div className={classes.description} dangerouslySetInnerHTML={{__html: author.biography}}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
