import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import { Ajax } from "../../helpers";
import Modal from '@mui/material/Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  mainImage: {
    width: "100%",
  },
  model: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  image: {
    width: "100%",
  },
  imageContainer: {
    padding: 8,
    cursor: "pointer"
  }
}));

export default function ShowGallery({ galleryParam = null }) {
  const classes = useStyles();
  let {slug, lang} = useParams();
  const [gallery, setGallery] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    if (galleryParam !== null) {
      setGallery(galleryParam)
    } else {
      Ajax(process.env.REACT_APP_BASE_URL + "/galleries/" + slug, lang)
          .then(res => res.json())
          .then(
              (result) => {
                setGallery(result);
              }
          );
    }
  }, [lang, slug, galleryParam]);

  if (gallery === null) {
    return <></>;
  }

  function handleClose() {
    setCurrentImage(null);
  }

  function handleOpen(image) {
    setCurrentImage(image);
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
        <Grid item xs={12}>
          <img alt="" src={gallery.image_link} className={classes.mainImage}/>
        </Grid>
        {
          gallery.images && gallery.images.map((image, index) => (
            <Grid item xs={6} md={4} key={index} className={classes.imageContainer} onClick={() => handleOpen(image)}>
              <img alt="" src={image} className={classes.image}/>
            </Grid>
          ))
        }
        <Modal
          className={classes.model}
          open={currentImage !== null}
          onClose={handleClose}
        >
          <div className={classes.paper}>
            <img alt="" src={currentImage} className={classes.image}/>
          </div>
        </Modal>
      </Grid>
    </Container>
  );
}
